<template>
  <div>
    <div class="rel-grid-container">
      <div ></div>
      <form v-on:submit.prevent="updateCountry" id="country-form" class="form-fix">
        <div>
          <span class="form-box-title">{{$t('country.settings_text')}}</span>
          <div id="country-box" class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('country.country_text')}}</span><b-form-select :state="states.id_state" v-model="country.countries_id" :options="countries" value-field="id" text-field="name" />
            <span class="form-box-name" >{{$t('country.languages_text')}}</span>
            <multiselect v-model="languages" :options="languageOptions" :placeholder="$t('country.choose_language')" :class="{'red-border': states.language_state==false}" label="name" track-by="name" class="blueselect country-multiselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="id" :close-on-select="false" :searchable="true">
              </multiselect>
          <span class="form-box-name">{{$t('country.timezones_text')}}</span>
            <multiselect v-model="timezones" :options="timezoneOptions" :placeholder="$t('country.choose_timezone')" :class="{'red-border': states.timezones_state==false}" label="text" track-by="text" class="blueselect country-multiselect" :noOptionsText="$t('global.empty_list')"  mode="tags" valueProp="value" :close-on-select="false" :searchable="true">
          </multiselect>
          </div>
        </div>
        <div>
          <span class="form-box-title">{{$t('country.national_text')}}</span>
          <div id="country-box" class="form-box grid-fitfirst-2column">
            <span class="form-box-name">{{$t('country.org_name')}}</span><b-form-input v-model="country.organization_name" type="text" class="medium-width-input" ></b-form-input>
            <span class="form-box-name">{{$t('member.phone_text')}}</span><b-form-input v-model="country.phone" type="tel" class="medium-width-input" ></b-form-input>
            <span class="form-box-name">{{$t('member.email_text')}}</span><b-form-input v-model="country.email" type="email"  name="whateveremail" autocomplete="new-email"></b-form-input>
            <span class="form-box-name">{{$t('general.address_text')}}</span><b-form-input v-model="country.address" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            <span class="form-box-name">{{$t('general.city_text')}}</span><b-form-input v-model="country.city" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" ></b-form-input>
            <span class="form-box-name">{{$t('general.postal_code')}}</span><b-form-input v-model="country.postal_code" type="text" pattern="[^<>]*" :title="$t('global.pattern_title')" maxlength="10"></b-form-input>
            <span v-if="country.country != ''" class="form-box-name">{{$t('general.region_text')}}</span><region-select v-if="country.country != ''" class="custom-select" v-model="country.region"  :country="country.country" :region="country.region" />
            
            <span class="form-box-name">{{$t('country.admin_name')}}</span><b-form-input :state="admin.name && admin.name.length>0 && !countryAdminNameInUse? null : false" v-model="admin.name" @input="checkAdminName('countryAdminNameInUse', admin.id, admin.name)" type="text" class="medium-width-input" ></b-form-input>
            <div v-if="countryAdminNameInUse" class="red-text second-column">{{ $t("global.name_duplicate") }}</div>
            <span class="form-box-name">{{$t('country.admin_password')}}</span><b-form-input v-model="admin.pass" :state="states.pass_state" type="password" class="medium-width-input" ></b-form-input>
            <span v-if="admin.pass.length>0" class="form-box-name">{{$t('country.retype_password')}}</span><b-form-input :state="admin.pass===admin.retype" v-if="admin.pass.length>0" v-model="admin.retype" type="password" class="medium-width-input" ></b-form-input>
          </div>
        </div>
        <div v-if="showAlert">
            <b-alert variant="danger" dismissible :show="showAlert" @dismissed="showAlert=false">
            {{this.$t('global.complete_fields')}}
            </b-alert>
        </div>
        <div class="submit-box-2">
          <b-button v-b-modal.modal-center v-if="country.id>0" class="action-button" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          <b-button class="action-button" v-on:click="back" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
          <b-button class="action-button" type="submit" variant="clear-blue"><div class="save-button"></div><span>{{$t('global.button_save')}}</span></b-button>
        </div>
        <b-modal id="modal-center" v-model="showDelete" centered :title="$t('country.delete_title')">
          <p class="my-4">{{$t('country.delete_text')}}</p>
          <div slot="modal-footer" class="submit-box-2">
            <b-button class="action-button" v-on:click="showDelete=false" variant="clear-blue"><div class="cancel-button"></div><span>{{$t('global.button_cancel')}}</span></b-button>
            <b-button class="action-button" v-on:click="deleteCountry" variant="clear-blue"><div class="delete-button"></div><span>{{$t('global.button_delete')}}</span></b-button>
          </div>
        </b-modal>
      </form>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<style scoped>
.country-multiselect{
  margin-bottom: 10px;
}
#country-fomr{
  max-width:800px;
}
</style>
<script>

    
  import router from "@/router"
    export default {
    name: "Country",
      data() {
        return {
          countryAdminNameInUse: false,
          languages: [],
          timezones: [],
          global_discount: 0,
            states:{
                id_state:null,
                language_state:null,
                timezones_state: null,
                admin_state: null,
                pass_state: null
            },
            showAlert: false,
            alertText: "",
          showDelete: false,
          countries: [],
          admin: {id: 0,name: '', pass: '', retype: ''},
          country: {id: 0, countries_id: 0, languages: [], timezones: [], generic_region_id: 0, monthly_price: 0,  
          currency_type: 0, monthly_discount: 0, trial_months: 1, trial_chapters: 1, tax: 0, currency_type: "",
          organization_name:'', address:'', city:'', region:'', postal_code:'', phone:'', email:'', country: '' },
          languageOptions: [],
          currencyTypes : [
          { text: "CAD", value: 'cad' },
          { text: "USD", value: 'usd' }],
        timezoneOptions : [
          { text: "(GMT -12:00) Eniwetok, Kwajalein", value: "546" },
          { text: "(GMT -11:00) Midway Island, Samoa", value: "562" },
          { text: "(GMT -10:00) Hawaii", value: "583" },
          { text: "(GMT -9:30) Taiohae", value: "548" },
          { text: "(GMT -9:00) Alaska", value: "577" },
          { text: "(GMT -8:00) Pacific Time (US &amp; Canada)", value: "379" },
          { text: "(GMT -7:00) Mountain Time (US &amp; Canada)", value: "377" },
          { text: "(GMT -6:00) Central Time (US &amp; Canada), Mexico City", value: "375" },
          { text: "(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima", value: "376" },
          { text: "(GMT -4:30) Caracas", value: "90" },
          { text: "(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz", value: "374" },
          { text: "(GMT -4:00/-5:00) Eastern Daylight Time, Toronto", value: "213" },
          { text: "(GMT -3:30) Newfoundland", value: "378" },
          { text: "(GMT -3:00) Brazil, Buenos Aires, Georgetown", value: "86" },
          { text: "(GMT -2:00) Mid-Atlantic", value: "411" },
          { text: "(GMT -1:00) Azores, Cape Verde Islands", value: "336"},
          { text: "(GMT) Western Europe Time, London, Lisbon, Casablanca", value: "451" },
          { text: "(GMT +1:00) Brussels, Copenhagen, Madrid, Paris", value: "433" },
          { text: "(GMT +2:00) Kaliningrad, South Africa", value: "446" },
          { text: "(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg", value: "458" },
          { text: "(GMT +3:30) Tehran", value: "316" },
          { text: "(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi", value: "245" },
          { text: "(GMT +4:30) Kabul", value: "275" },
          { text: "(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent", value: "277" },
          { text: "(GMT +5:30) Bombay, Calcutta, Madras, New Delhi", value: "251" },
          { text: "(GMT +5:45) Kathmandu, Pokhara", value: "279" },
          { text: "(GMT +6:00) Almaty, Dhaka, Colombo", value: "259" },
          { text: "(GMT +6:30) Yangon, Mandalay", value: "330" },
          { text: "(GMT +7:00) Bangkok, Hanoi, Jakarta", value: "246" },
          { text: "(GMT +8:00) Beijing, Perth, Singapore, Hong Kong", value: "360" },
          { text: "(GMT +8:45) Eucla", value: "352" },
          { text: "(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk", value: "+09:00" },
          { text: "(GMT +9:30) Adelaide, Darwin", value: "320" },
          { text: "(GMT +10:00) Eastern Australia, Guam, Vladivostok", value: "328" },
          { text: "(GMT +10:30) Lord Howe Island", value: "356" },
          { text: "(GMT +11:00) Magadan, Solomon Islands, New Caledonia", value: "394" },
          { text: "(GMT +11:30) Norfolk Island", value: "552" },
          { text: "(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka", value: "528" },
          { text: "(GMT +12:45) Chatham Islands", value: "530" },
          { text: "(GMT +13:00) Apia, Nukualofa", value: "527" }
        ],
        }
      },
        methods: {
          checkAdminName(varName, id, name){
            this.$http.post("/api/member/admin/name/check", {id: id, name: name}).then((response) => {
                if (response.data.success == true) {
                 this.$set(this, varName, response.data.check);
                }
              }).catch(() => {
              });
          },
            getLanguages() {
            this.$http.post("/api/superadmin/languages", {}).then((response) => {
            if (response.data.success) {
                this.languageOptions = response.data.languages;
                this.getCountry();
            }
            }).catch(() => {
            });
        },
        getCountries(){
            this.$http.post("/api/countries", {}).then((response) => {
            if (response.data.success) {
                this.countries = response.data.countries;
            }
            }).catch(() => {});
          },
          getCountry() {
            this.country.id = this.$route.params.id;
            if (this.country.id > 0) {
              this.$http.post("/api/superadmin/country/get", this.country).then((response) => {
                if (response.data.success) {
                    this.languages = response.data.country.languages.split(",");
                    this.timezones = response.data.country.timezones.split(",");
                    this.country = response.data.country;
                    this.admin.name = response.data.admin.name;
                    this.admin.id = response.data.admin.id;
                }
              }).catch(() => {});
            } 
          },
          checkData(){
               this.showAlert = false;
                this.states ={
                    id_state: null,
                    language_state: null,
                    timezones_state: null,
                    admin_state: null,
                    pass_state: null
                };
                var allgood = true;
                if(this.country.countries_id==0){
                    allgood = false;
                    this.states.id_state = false;
                }
                if(this.languages.length == 0){
                    allgood = false;
                    this.states.language_state = false;
                }
                if(this.timezones.length == 0){
                    allgood = false;
                    this.states.timezones_state = false;
                }
                if(this.country.id==0 && this.admin.pass!=null && this.admin.pass.length==0){
                  allgood = false;
                  this.states.pass_state = false;
                }
                if(this.admin.pass && this.admin.pass.length>0 && this.admin.pass != this.admin.retype){
                  allgood = false;
                  this.states.pass_state = false;
                }
                if(this.admin.name== null || this.admin.name.length==0){
                  allgood = false;
                  this.states.admin_state = false;
                }
                if(this.countryAdminNameInUse){
                  allgood = false;
                }
                this.showAlert = !allgood;
                return allgood;
          },
          updateCountry() {
            if(!this.checkData()){
                return;
            }
            this.country.postal_code = this.country.postal_code.substring(0,10);
            this.country.languages = this.languages.join(",");
            this.country.timezones = this.timezones.join(",");
            this.$http.post("/api/superadmin/country/update", {country: this.country, admin: this.admin}).then((response) => {
              if (response.data.success === true) {
                this.saveVariant = 'success';
                this.back();
              } else {
                this.saveVariant = 'danger';
              }
            }).catch(() => {this.$bvToast.show('serverError');});
          },
          deleteCountry() {
            this.$http.post("/api/superadmin/country/delete", this.country).then((response) => {
              if (response.data.success === true) {
                this.back();
              }
            }).catch(() => {});
          },
          back(){
            window.history.length > 1 ? router.go(-1) : router.push("/admin/countries");
          }
    },
    destroyed() {
      this.removeClass(document.getElementById("menu-countries"), "active-menu");
      this.removeClass(document.getElementById("menu-admin"), "active-menu");
    },
    mounted() {
      this.addClass(document.getElementById("menu-countries"), "active-menu");
      this.addClass(document.getElementById("menu-admin"), "active-menu");
      this.getCountries();
      this.getLanguages();
    }
    }
</script>
